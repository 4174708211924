'use client'

import { type StorefrontWidget, type WidgetRelatedProducts } from 'ecosystem'
import RelatedProductsWidgetBase from 'storefront-modules/products/widgets/RelatedProductsWidgetBase'
import { OverridesContextType, overrideText } from 'ui/lib/overrides'
import { useGetProductsQuery } from 'shared-redux/state'
import useProductRequest from '#/src/custom/products/useProductRequest'
import { useCustomCart } from '#/src/custom/cart/useCustomCart'
import appConfig from '#/src/appConfig'
import { hrefFromPathObj, isExternalLink } from 'shared-utils'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { ShowAllOverrides } from 'storefront-modules/types'

interface RelatedProductsWidgetProps {
  widget: StorefrontWidget<WidgetRelatedProducts>
  overrides?: OverridesContextType
}

export const CustomRelatedProductsWidget = ({ widget }: RelatedProductsWidgetProps) => {
  const overrides = useOverridesContext<keyof ShowAllOverrides>()
  const { data, isLoading } = useGetProductsQuery({
    filter: { productIds: widget.widget.relatedProducts.map((rp) => rp.id) }
  })
  const { handleProductRequest } = useProductRequest()
  const { handleAddToCart } = useCustomCart()

  return (
    <RelatedProductsWidgetBase
      key={widget.widget.name}
      config={{
        title: widget.widget.title,
        ...(widget.widget.pathObj
          ? {
              headingLink: {
                title: overrideText('Visa alla', overrides?.showAll),
                href: hrefFromPathObj(widget.widget.pathObj, {
                  category: appConfig.paths.categoriesUrlPath,
                  brand: appConfig.paths.brandsUrlPath,
                  tag: appConfig.paths.tagsUrlPath,
                  blog: appConfig.paths.blogsUrlPath
                }),
                isExternal: isExternalLink(widget.widget.pathObj.slug)
              }
            }
          : {}),
        relatedProducts: data?.content || [],
        onProductRequest: handleProductRequest,
        deliveryOptions: appConfig.storeDeliveries,
        onProductBuy: handleAddToCart,
        paths: {
          productsUrlPath: appConfig.paths.productsUrlPath
        },
        itemCount: data?.content?.length || 0,
        isLoading
      }}
    />
  )
}
