'use client'
import { type FC, useCallback } from 'react'
import type { CartItem, DeliveryDTO, ProductLightDTO } from 'ecosystem'
import { OverridesContextProvider, type OverridesContextType } from 'ui/lib/overrides'
import type { GrandSectionHeadingLink } from 'ui/storefront/layout/GrandSection'
import { FeaturedProducts } from '../components'

export interface TagProductsWidgetBaseConfig {
  itemCount?: number
  products: ProductLightDTO[]
  title: string
  onProductBuy: (item: CartItem) => void
  onProductRequest: (item: ProductLightDTO) => void
  deliveryOptions: DeliveryDTO[] | null
  overrides?: OverridesContextType
  paths: {
    productsUrlPath: string
  }
  isLoading?: boolean
  headingLink?: GrandSectionHeadingLink
}

interface TagProductsWidgetBaseProps {
  config: TagProductsWidgetBaseConfig
}

const TagProductsWidgetBase: FC<TagProductsWidgetBaseProps> = ({
  config: {
    overrides,
    itemCount = 10,
    onProductBuy,
    onProductRequest,
    deliveryOptions,
    paths,
    products,
    title,
    headingLink
  }
}) => {
  const handleAdd = useCallback(
    (product: ProductLightDTO) => {
      const cartItem: CartItem = {
        id: product.id,
        qty: 1,
        type: 'SINGLE',
        meta: {
          singleType: {
            product
          }
        }
      }
      onProductBuy(cartItem)
    },
    [onProductBuy]
  )

  const limitedTagProducts = products.slice(0, itemCount)

  return (
    <OverridesContextProvider overrides={{ ...overrides }}>
      <FeaturedProducts
        items={limitedTagProducts}
        onBuy={handleAdd}
        productsUrlPath={paths.productsUrlPath}
        sectionId="products-tag"
        title={title || ''}
        carouselItemsByBreakpoint={{ sm: 1, md: 5, lg: 6 }}
        {...{ deliveryOptions, onProductRequest, headingLink }}
      />
    </OverridesContextProvider>
  )
}

export default TagProductsWidgetBase
